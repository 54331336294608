/* SwippablePhotos.css */
.swipe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: auto; /* Hauteur minimale pour garantir l'esthétique */
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 20px 0; /* Léger padding en haut et en bas */
}

.overlay {
  background-color: rgba(34, 73, 58, 0.8); /* Filtre vert personnalisé */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-card {
  flex: 1;
  padding: 20px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.service-card h4 {
  margin-bottom: 10px;
  color: #333;
}

.service-card p {
  color: #666;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  z-index: 1;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

/* Swipy.css */
.swipy-container {
  padding: 40px;
  background-color: #fafafa;
  margin: 20px 0; /* Léger espace en haut et en bas pour éviter le chevauchement */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

h2 {
  text-align: center;
  margin-bottom: 40px;
  color: #777;
}

.photos-wrapper {
  display: flex;
  gap: 20px; /* Ajouter un espace entre les conteneurs */
}

.photo-container {
  flex: 1;
  min-height: auto; /* Hauteur minimale pour garantir l'esthétique */
  position: relative;
  padding: 20px 0; /* Léger padding en haut et en bas */
}
.min-title {
  color: #22493A; /* Vert moderne */
  font-size: 1.5em; /* Taille de police par défaut */
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

.min-title::after {
  content: '';
  display: block;
  width: 40%;
  height: 3px;
  background-color: #22493A; /* Même couleur verte */
  margin: 10px auto 0; /* Centrer le soulignement */
}

@media (max-width: 768px) {
  .photos-wrapper {
    flex-direction: column;
    padding-left: 0em;
    padding-right: 0em;
  }
  h1 {
  font-size: 1.6em;
}

h2 {
  font-size:1em ;
}

}
