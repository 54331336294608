/* Footer.css */

.footer {
  background-color: #22493A;
  padding: 2em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.footer-info, .footer-links, .footer-logos {
  flex: 1;
  min-width: 200px;
  margin: 1em 0;
}

.footer-info {
  color: white;
}

.contact-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-link:hover {
  text-decoration: underline;
}

.phone-icon, .mail-icon {
  margin-right: 0.5em;
  width: 3.5em;
  height: auto;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  color: white;
}

.footer-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 0.5em;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logos img {
  width: 10em;
  height: auto;
  margin: 0 1em;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 1em;
  color: white;
  font-size: 0.8em; /* Petite taille de police */
}

.footer-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease; /* Transition pour le changement de couleur */
}

.footer-link:hover {
  color: #76c7c0; /* Vert pour l'animation de survol */
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-info, .footer-links, .footer-logos {
    margin: 1em 0;
  }

  .footer-links {
    gap: 0.5em;
  }

  .footer-links a {
    font-size: 3.8vw;
  }

  .phone-icon, .mail-icon {
    width: 3.2em;
  }

  .footer-logos img {
    width: 8em;
  }
}
