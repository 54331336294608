.projet-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  background-color: white;
  color: black;
  overflow: hidden;
  margin-bottom: 5em;
}

.image-container {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateX(-100px); /* Animation d'apparition */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.image-container.show {
  opacity: 1;
  transform: translateX(0);
}

.image-border {
  position: relative;
  padding: 0em;
  background: #22493A; /* Vert foncé */
}

.img {
  width: 100%;
  height: auto;
  display: block;
}

.text-container {
  align-items: center;
  flex: 1;
  padding-left: 2em;
  opacity: 0;
  transform: translateX(100px); /* Animation d'apparition */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.text-container.show {
  opacity: 1;
  transform: translateX(0);
}

.text-container h2 {
  align-items: center;
  color: #22493A;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.text-container p {
  font-size: 1em;
}

.button-container {
  margin-top: 1em;
}

.contact-button {
  background-color: #22493A;
  color: white;
  border: none;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-button:hover {
  background-color: #1C382D;
  transform: scale(1.1);
}

/* Version mobile */
@media (max-width: 768px) {
  .projet-container {
    flex-direction: column;
    padding: 1em 1em 0; /* Réduire le padding en haut */
    text-align: center; /* Centrer tous les textes */
  }

  .image-container {
    margin-bottom: 1em;
  }

  .text-container {
    padding-left: 0;
    text-align: center; /* Centrer le texte */
  }

  .text-container h2 {
    font-size: 1.5em;
  }

  .text-container p {
    font-size: 0.9em;
  }

  .contact-button {
    font-size: 0.9em;
    padding: 0.4em 0.8em;
  }
}
