.App {
  text-align: center;
}

.App-header {
  background-color: orange;
  padding: 0.5em;
  color: blue;
  height: 0.5px;
  width: auto;
}

main {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 1vw;
  padding-top: 7vw;
}

@media (max-width: 768px) {
  main {
  margin-top: 0em;
    padding-top: 0vw;
}
}