@import url('https://fonts.googleapis.com/css2?family=Allegreya+SC:wght@400;700&display=swap');

/* Animation CSS */
.fade-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

.background {
  padding-left: 3em;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Ajuster la hauteur pour prendre toute la hauteur de la fenêtre */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  filter: brightness(100%);
  position: relative;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Ressources/new_good_photo.avif');
  background-size: 140%;
  background-position: center 45%;
  filter: brightness(30%);
  z-index: -1;
}

.content {
  text-align: center;
  color: white;
}

.content h1 {
  font-size: 8vw;
  margin-bottom: 0.5em;
  font-family: 'Allegreya SC', serif;
}

.content p {
  font-size: 2.8vw;
}

.content .quote {
  font-size: 1.3vw; /* Taille réduite du verset */
  font-style: italic;
  margin-top: 1em;
}

.buttons {
  margin-top: 1em;
}

.button_p {
  background-color: #22493A;
  color: white;
  border: none;
  padding: 0.5em 1em;
  margin-right: 1em;
  font-size: 1.6vw;
  cursor: pointer;
  transition: background-color 0.3s, transform 1s;
}

.button_p:hover {
  background-color: #1C382D;
  transform: scale(1.1);
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
  .background {
    padding-right: 2em;
    height: auto;
    background-image: url('../Ressources/new_good_photo.avif');
    background-size: 220%;

    /* Réduire la hauteur pour les petits écrans */
  }

  .content h1 {
    padding-right: 0em;
    padding-left: 0em;
    font-size: 12.0vw;
    padding-top: 1em;
    /* Réduire la taille de la police pour les titres */
  }

  .content p {
    padding-right: 0em;
    padding-left: 0em;
    font-size: 01.05em; /* Réduire la taille de la police pour les paragraphes */
  }

  .content .quote {
    font-size: 0.6em; /* Réduire davantage la taille de la police pour les citations */
  }

  .buttons {
    margin-top: 0.5em;
  }

  .button_p {
    padding: 0.5em 1.3em; /* Réduire le padding pour les boutons */
    font-size: 0.6em; /* Réduire la taille de la police pour les boutons */
    margin-right: 0.4em; /* Réduire l'espacement entre les boutons */
  }
}
