.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #22493A; /* Vert de votre choix */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: transform 1s ease-out;
}

.splash-screen.slide-up {
  transform: translateY(-100%);
  transition: transform 1s ease-out;
}

.splash-logo {
  width: 20em; /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}
