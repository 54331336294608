/* ExtraitProjets.css */

/* Animation CSS */
.fade-in-top {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.fade-in-top.loaded {
  opacity: 1;
  transform: translateY(0);
}

.project-item {
  position: relative;
  width: calc(33.33% - 2em); /* Largeur de chaque élément (1/3 de la largeur) */
  margin: 1em;
  cursor: pointer;
  overflow: hidden;
  opacity: 0; /* Définir l'opacité initiale à 0 */
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.project-item.loaded {
  opacity: 1;
  transform: translateY(0);
}

.project-list {
  display: flex;
  justify-content: space-around; /* Espacement égal entre les éléments */
  flex-wrap: wrap; /* Permettre le retour à la ligne des éléments */
}

.project-item img {
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease-out;
}

.project-item:hover img {
  transform: scale(1.1); /* Zoom au survol */
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent noir */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.project-item:hover .project-overlay {
  opacity: 1; /* Affichage de l'overlay au survol */
}

.overlay-content {
  text-align: center;
  padding: 1em;
}

.overlay-content p {
  font-size: 1em; /* Taille de la police pour la description du projet */
  color: white; /* Couleur du texte en blanc */
}

.arrow {
  position: relative;
  display: inline-block;
  width: 1em; /* Largeur de la flèche */
  height: 0.1em; /* Épaisseur de la flèche */
  background-color: white; /* Couleur de la flèche */
  transform: rotate(0deg); /* Rotation de la flèche */
}

.arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -0.25em; /* Ajustement pour l'alignement */
  transform: translateY(-50%);
  width: 0.5em; /* Largeur du triangle */
  height: 0.5em; /* Hauteur du triangle */
  background-color: white; /* Couleur du triangle */
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
}

.view-projects-button {
  display: inline-block;
  background-color: #22493A; /* Couleur de fond marron */
  color: white; /* Couleur du texte en blanc */
  border: none;
  padding: 0.7em 2em;
  margin-top: 2em; /* Marge en haut du bouton */
  font-size: 1em;
  cursor: pointer;
  opacity: 0; /* Initial opacity */
  transition: opacity 2s ease-out, transform 2s ease-out, background-color 0.3s, transform 1s;
}

.view-projects-button.loaded {
  opacity: 1;
  transform: translateY(0);
}

.view-projects-button:hover {
  background-color: #1C382D;
  transform: scale(1.1);
}

.ExtraitProjets {
  padding: 1.5em;
  text-align: center;
  color: #333; /* Couleur du texte */
  margin-left: 6em;
  margin-right: 6em;
}

.ExtraitProjets h2 {
  font-size: 3em; /* Taille de la police pour le titre */
  margin-bottom: 0.5em; /* Marge en bas du titre */
  color: #22493A;
}

.ExtraitProjets p {
  font-size: 1.2em; /* Taille de la police pour le paragraphe */
  line-height: 1.5; /* Espacement entre les lignes du paragraphe */
  margin-bottom: 2em; /* Marge en bas du paragraphe */
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .ExtraitProjets {
    margin-left: 0em;
    margin-right: 0em;
  }

  .project-item {
    width: 100%; /* Prend toute la largeur */
    margin: 0.5em 0; /* Moins de marge */
  }

  .ExtraitProjets h2 {
    font-size: 2em; /* Réduire la taille de la police pour le titre */
  }

  .ExtraitProjets p {
    font-size: 1em;/* Réduire la taille de la police pour le paragraphe */
  }

  .overlay-content p {
    font-size: 0.8em; /* Réduire la taille de la police pour la description du projet */
  }

  .view-projects-button {
    padding: 0.5em 1em; /* Réduire le padding du bouton */
    font-size: 0.9em; /* Réduire la taille de la police du bouton */
  }
}

