.language-selector {
  position: relative;
  display: inline-block;
}

.language-button {
  background: none; /* Supprime l'arrière-plan par défaut */
  border: none; /* Supprime la bordure par défaut */
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: transform 0.3s, box-shadow 0.3s; /* Ajoute une transition pour l'effet de grossissement et l'ombre */
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-button:hover {
  transform: scale(1.1); /* Grossissement */
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.001); /* Effet d'ombre */
  color: inherit; /* Assure que la couleur reste la même */
  background: none; /* Assure que l'arrière-plan reste transparent */
  outline: none; /* Supprime l'effet de focus par défaut */
}

.language-button img {
  width: 2em;
  height: auto;
  transition: transform 0.3s ease; /* Ajoute une transition douce à l'image */
}

.language-button:hover img {
  transform: scale(1.1); /* Applique un zoom de 10% à l'image lors du survol */
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.language-option:hover {
  background-color: #f0f0f0;
}

.language-option span {
  color: #22493A; /* Couleur verte */
  transition: color 0.3s;
  font-size: 0.6em;
}

.language-option:hover span {
  color: #1C382D; /* Vert plus clair au survol */
}

.language-option img {
  width: 2em;
  height: auto;
  margin-right: 8px;
}
