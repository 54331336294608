.swipe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px; /* Augmenter la hauteur pour éviter le chevauchement */
  padding: 20px; /* Ajouter un joli padding */
  background-size: cover;
  background-position: center;
  position: relative;
  box-sizing: border-box; /* Assurer que padding est pris en compte dans la taille totale */
}
/* SwippablePhotos.css */
/* SwippablePhotos.css */
.arrowi {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  border: none; /* Assurez-vous qu'il n'y ait pas de bordure qui pourrait apparaître */
}

.arrowi-left {
  left: 0.2em;
}

.arrowi-right {
  right: 0.2em;
}

.arrowi svg {
  width: 20px;
  height: 20px;
  fill: white; /* Changer la couleur des flèches en blanc */
}


.overlay {
  background-color: rgba(0, 128, 0, 0.5); /* Filtre vert */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box; /* Assurer que padding est pris en compte dans la taille totale */
}

.page-indicator-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.page-indicator {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.service-card {
  width : 80%;
  flex: 1;
  padding: 20px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px; /* Augmenter la largeur maximale */
}

.service-card h4 {
  margin-bottom: 10px;
  color: #333;
}

.service-card p {
  color: #666;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Marge pour séparer les points des cartes de service */
  position: absolute;
  bottom: 20px; /* Toujours en bas du conteneur */
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

/* Swipy.css */
.swipy-container {
  padding: 40px;
  background-color: #fafafa;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

h2 {
  text-align: center;
  margin-bottom: 40px;
  color: #777;
}

.photos-wrapper {
  display: flex;
  gap: 20px; /* Ajouter un espace entre les conteneurs */
}

.photo-container {
  flex: 1;
  height: 600px; /* Ajustez la hauteur selon vos besoins */
  position: relative;
}

@media (max-width: 768px) {
  .service-card {
  }

  p {
    font-size: 0.9em;
  }

  .dots-container {
    bottom: 10px; /* Ajustez la position pour les téléphones */
  }
}
