.steps-container {
  padding: 2em 0;
  padding-top: 0em;
}

.steps-title {
  text-align: center;
  color: #22493A;
  font-size: 2em;
  margin-bottom: 1em;
}

.steps {
  display: flex; /* Changer de column à flex pour l'alignement horizontal */
  width: 100%;
  height: auto;
  overflow: hidden;
}

.step {
  flex: 1; /* Assurer que chaque étape prenne une largeur égale */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  opacity: 0; /* Initialement invisible */
  transform: translateY(100px); /* Initialement déplacé vers le bas */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.step.show {
  opacity: 1;
  transform: translateY(0); /* Animation glisse de bas en haut */
}

.step-content {
  text-align: left;
}

.step h2 {
  font-size: 2em;
  margin-bottom: 0.5em;
  align-self: flex-start;
}

.step1 {
  background: #A3D9B1; /* Vert clair */
  color: #22493A;
}

.step2 {
  background: #5CB68A;
}

.step3 {
  background: #2D8F6B;
}

.step4 {
  background: #22493A;
}



@media (max-width: 768px) {

 .steps-title {

  color: #22493A;
  font-size: 1em;
   margin-bottom: 1.5em;

}

  .steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em; /* Espacement entre les carrés */
  width: 100%;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  font-weight: bold;
  font-size: 0.9em; /* Taille de police réduite */
  opacity: 0; /* Initialement invisible */
  transform: translateY(100px); /* Initialement déplacé vers le bas */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  height: 10em; /* Fixer une hauteur pour les carrés */
  box-sizing: border-box;
  padding-top: 2em;
}

.step.show {
  opacity: 1;
  transform: translateY(0); /* Animation glisse de bas en haut */
}

.step-content {
  text-align: left;
}

.step h2 {
  font-size: 1.5em; /* Taille de police réduite pour le titre */
  margin-bottom: 0.5em;
  align-self: flex-start;
}
.step p{
  font-size: 0.7em;
}
  .step {
    flex: 1 1 100%; /* Chaque carré prend 100% de la largeur sur mobile */
    max-width: 100%;
    height: auto; /* Ajuster la hauteur automatiquement */
  }
}