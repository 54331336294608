.gestion-construction {
  background-image: url('../Ressources/Photo_12.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 100vh;
  margin-bottom: 3em;
margin-top: 2em;
}

.overlay {
  background-color: rgba(34, 73, 58, 0.85);
  position: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2em;
  box-sizing: border-box;
}

.overlay h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.overlay h2 {
  font-size: 1.2em;
  margin-bottom: 2em;
  text-align: center;
}

.services {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gestion, .construction {
  width: 45%;
  position: relative;
}

.gestion h3, .construction h3 {
  font-size: 1.2em;
  margin-bottom: 1em;
  text-align: center;
}

.service-column-wrapper {
  overflow: hidden;
  position: relative;
}

.service-column {
  display: flex;
  flex-direction: column;
  gap: 1em;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.service-item {
  background-color: #1C382D;
  padding: 1em;
  text-align: center;
  border-radius: 5px;
  font-size: 1em;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.service-item.show {
  opacity: 1;
  transform: translateX(0);
}

.service-item.hide {
  opacity: 0;
  transform: translateX(100%);
}


@media (max-width: 768px) {
  .gestion-construction {
    min-height: auto;
  }

  .overlay {
    padding: 1em;
  }

  .overlay h1 {
    font-size: 1.8em;
  }

  .overlay h2 {
    font-size: 1em;
    margin-bottom: 1.5em;
  }

  .services {
    flex-direction: column;
    align-items: center;
  }

  .gestion, .construction {
    width: 100%;
    margin-bottom: 2em;
  }

  .gestion h3, .construction h3 {
    font-size: 1em;
  }

  .service-item {
    font-size: 0.9em;
  }

  button {
    font-size: 0.9em;
    margin-bottom: 1em;
  }
}
