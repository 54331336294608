/* Animation CSS */
.fade-in-top {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.fade-in-top.show, .fade-in-left.show, .fade-in-right.show {
  opacity: 1;
  transform: translate(0);
}

.new-section-TP {
  display: flex;
  align-items: center;
  padding-top: 2.5em;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0em;
  background-color: #22493A; /* Couleur de fond marron */
  color: white; /* Couleur du texte en blanc */
  margin-bottom: 0em;
  flex-direction: row;
}

.new-section-TP .content {
  margin-left: 8em; /* Ajoute une marge à gauche */
  margin-right: 8em; /* Ajoute une marge à droite */
  flex-direction: row;
  display: flex;
  padding-top: 0em;
  padding-bottom: 0em;
}

.new-section-TP .image-container-TP {
  position: relative;
  text-align: center;
  width: 50%;
  padding-bottom: 0em;
}

.new-section-TP .image-container-TP img {
  max-width: 100%;
  height: auto;
  padding-bottom: 0em;
}

.new-section-TP .image-container-TP .top-image {
  margin-bottom: 1em; /* Espace entre l'image du haut et celles du bas */
}

.new-section-TP .image-container-TP .bottom-images {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin-top: 0.5em;
  padding-bottom: 0em;/* Espacement entre les images */
}

.new-section-TP .coco {
  height: 30%;
}
.new-section-TP .bottom-images {
  padding-left: 3em;
  padding-bottom: 0em;
}

.new-section-TP .bottom-images .image-4 {
  width: 63%;
  height: 100%;
  padding-left: 3em;
  padding-bottom: 0em; /* Taille plus petite pour Image 4 */
}

.new-section-TP .bottom-images .image-5 {
  width: 45%; /* Taille plus petite pour Image 5 */
  padding-bottom: 0em;
}

.new-section-TP .text-container-TP {
  width: 50%;
  text-align: left; /* Alignement du texte à gauche */
}

.new-section-TP .text-container-TP h2 {

  font-size: 1.5em;
  margin-bottom: 1em;
  font-style: italic; /* Style oblique */
}

.new-section-TP .text-container-TP p {
  font-size: 0.8em;
  line-height: 1.5;
}

.buttons-TP {
  margin-top: 1em;
}

.button-TP {
  background-color: white;
  color: #22493A;
  border: none;
  padding: 0.5em 1em;
  margin-right: 1em;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 1s;
}

.button-TP:hover {
  background-color: white;
  transform: scale(1.1);
}

/* Classe pour masquer les images sur les petits écrans */
.hidden-mobile {
  display: none;
}

@media (min-width: 769px) {
  .hidden-mobile {
    display: block;
  }
}

@media (max-width: 768px) {
  .new-section-TP {
    flex-direction: column;
    padding: 1em;
  }

    .new-section-TP .content {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }

  .new-section-TP .text-container-TP {
    width: 100%;
    text-align: center;
  }

  .new-section-TP .text-container-TP h2 {
    font-size: 0.83em;
  }

  .new-section-TP .text-container-TP p {
    font-size: 0.8em;
  }

  .new-section-TP .image-container-TP {
    width: 100%;
    padding-bottom: 1em;
    align-items: center;
  }

  .new-section-TP .bottom-images {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }

  .new-section-TP .bottom-images .image-4,
  .new-section-TP .bottom-images .image-5 {
    width: 80%;
    padding-left: 0;
  }

  /* Masquer les images img1 et img4 sur mobile */
  .hidden-mobile {
    display: none;
  }
}
